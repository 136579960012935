@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

:root {
  --main-color: #51806f;
  --error-color: #E75635;
}

@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 100;
  src: local("Work Sans Light"), local("Work Sans Light"),
    url(/public/assets/fonts/WorkSans-Light.ttf);
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Work Sans Regular"), local("Work Sans Regular"),
    url(/public/assets/fonts/WorkSans-Regular.ttf);
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  src: local("Work Sans Medium"), local("Work Sans Medium"),
    url(/public/assets/fonts/WorkSans-Medium.ttf);
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Work Sans SemiBold"), local("Work Sans SemiBold"),
    url(/public/assets/fonts/WorkSans-SemiBold.ttf);
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Work Sans Bold"), local("Work Sans Bold"),
    url(/public/assets/fonts/WorkSans-Bold.ttf);
}
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  src: local("DM Sans Regular"), local("DM Sans Regular"),
    url(/public/assets/fonts/DMSans-Regular.ttf);
}
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  src: local("DM Sans Medium"), local("DM Sans Medium"),
    url(/public/assets/fonts/DMSans-Medium.ttf);
}
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  src: local("DM Sans SemiBold"), local("DM Sans SemiBold"),
    url(/public/assets/fonts/DMSans-SemiBold.ttf);
}
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  src: local("DM Sans Bold"), local("DM Sans Bold"),
    url(/public/assets/fonts/DMSans-Bold.ttf);
}
@font-face {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 400;
  src: local("SF Pro Regular"), local("SF Pro Regular"),
    url(/public/assets/fonts/SFPRODISPLAYREGULAR.OTF);
}
@font-face {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 500;
  src: local("SF Pro Medium"), local("SF Pro Medium"),
    url(/public/assets/fonts/SFPRODISPLAYMEDIUM.OTF);
}
@font-face {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 700;
  src: local("SF Pro Bold"), local("SF Pro Bold"),
    url(/public/assets/fonts/SFPRODISPLAYBOLD.OTF);
}
* {
  font-family: Work Sans;
}
.copyRightSection {
  border-radius: 30px 30px 0px 0px;
  background: rgb(35, 31, 32);
  background: linear-gradient(
    180deg,
    rgba(35, 31, 32, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
  padding: 15px 0px;
}
.copyRightSection span {
  width: 1px;
  height: 16px;
  background-color: white;
}
.copyRightSection p {
  color: white;
  margin-bottom: 0px;
}
input:focus {
  box-shadow: none !important;
  outline: none;
  border: 1px solid var(--main-color) !important;
}
.form-control:focus {
  box-shadow: none !important;
  outline: none;
  border: 1px solid var(--main-color) !important;
}
.form-label {
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.nav-link {
  color: #231f20;
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
.customDivider {
  width: 1px;
  background-color: #e8e8e8;
}
.sellArtworks {
  padding: 30px 40px;
  border-radius: 20px;
  background-color: var(--main-color);
}
.sellArtworks h2 {
  font-size: 32px;
}
.sellArtworks p {
  font-size: 20px;
  margin-bottom: 0px;
}
.sellArtworks a {
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid white;
  color: var(--main-color);
  border-radius: 10px;
  transition: all linear 0.2s;
  text-decoration: none;
  white-space: nowrap;
  padding: 0px 30px;
}
.sellArtworks a:hover {
  background-color: transparent;
  color: white;
  border: 1px solid white;
}
.footerMain {
  background: linear-gradient(180deg, #231f20 0%, #000000 100%);
  padding-top: 80px;
}
.footerMain p {
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: white;
  margin-top: 20px;
}
.footerMain h4 {
  font-family: Work Sans;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.15px;
  text-align: left;
  color: white;
}
.mailListMain {
  margin-bottom: -80px;
}
.footerMain .iconMain {
  width: 42.26px;
  height: 42.26px;
  border-radius: 13px;
  background-color: #f2f2f22b;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.iconMain:hover {
  background-color: var(--main-color);
}
.footerMain ul {
  padding-left: 0px;
  margin-top: 20px;
}
.footerMain ul li {
  list-style: none;
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: white;
  margin-bottom: 10px;
}
.primary {
  color: var(--main-color);
}
.bg-pink {
  background-color: #f4efe6;
  padding: 30px 0px;
}

@media screen and (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1420px !important;
  }
  .handleLarger {
    padding-left: 130px !important;
  }
}
@media (max-width: 1399px) {
  .dashboardMain .tabs {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .row .custom-col {
    flex: 0 0 23%;
    max-width: 23%;
  }
}
@media screen and (max-width: 991px) {
  .modal.show .modal-dialog {
    max-width: 95%;
  }
  .row .custom-col {
    flex: 0 0 31.33%;
    max-width: 31.33%;
  }
  .btnsHolder {
    position: absolute;
    right: 75px;
    top: 40px;
  }
  .profileHolder {
    position: absolute;
    right: 60px;
    top: 27px;
  }
  main .profileHolder {
    position: static;
  }
}
@media screen and (max-width: 767px) {
  .dashboardMain .tabs {
    margin-bottom: 20px;
  }
  .dashboardMain .tabs button {
    width: 100%;
  }
  .notifier {
    width: 100%;
    height: auto;
  }
  .notifier p {
    white-space: unset;
  }
  .createListingMain .floatingTag {
    margin-left: auto;
  }
  .contactMain .holder {
    width: 50px;
    height: 50px;
    border-radius: 10px;
  }
  .contactMain h5 {
    font-size: 20px;
    margin-bottom: 0px;
  }
  .contactMain p {
    font-size: 16px;
  }
  .contactMain .holder img {
    width: 20px;
  }
  .faqTabs {
    flex-wrap: wrap;
  }
  .faqTabs button {
    width: calc(33.33% - 14px);
  }
  .faqTabs .search {
    width: 100%;
  }
  .tabs {
    width: 100%;
  }
  .servedIntroMain .row div:nth-child(2) {
    border-right: none;
  }
  .horizonalSlider.slider-container .thumbs-swiper img {
    height: 100px;
  }
  .aboutSeller .tag {
    width: fit-content;
    margin-bottom: 10px;
  }
  .aboutSellerMain {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .thumbs-swiper {
    width: 100%;
    height: auto !important;
    margin-top: 10px;
  }
  .slider-container {
    display: block;
    flex-direction: column;
  }
  .mailList input {
    width: 100% !important;
  }
  .swiper-button {
    width: 56px;
    height: 56px;
  }
  .row .custom-col {
    flex: 0 0 48%;
    max-width: 48%;
  }
  .swiper-pagination {
    left: 0px !important;
    text-align: center !important;
    bottom: 20px !important;
  }
  .swiper-slide h2 {
    font-size: 42px;
    line-height: 40px;
  }
  .swiper-slide p {
    font-size: 14px;
  }
  .h-100vh {
    min-height: auto;
  }
  .loginLeft .h-100 {
    height: auto !important;
  }
  .loginLeft {
    padding: 20px 0px;
  }
  .registerMain {
    position: static;
    transform: none;
  }
  .copyRightSection {
    position: static;
    inset: 0;
  }
}
@media screen and (max-width: 575px) {
  .profileBtn{
    width: 100% !important;
    height: 47px;
    margin-bottom: 5px;
  }
  .notifier {
    top: -10px;
  }
  .faqTabs {
    gap: 10px;
  }
  .faqTabs button {
    width: calc(100%);
    margin-bottom: 0px;
  }
  .servedIntroMain .row div {
    border-right: none;
  }
  .openBidModal .formMain {
    width: 100% !important;
  }
  .horizonalSlider.slider-container .thumbs-swiper img {
    height: 80px;
    border-radius: 10px;
  }
  .detailData h3 {
    font-size: 25px;
  }
  .font-20,
  .detailData .cardInner p {
    font-size: 16px !important;
  }
  .cardInner .tag {
    width: fit-content;
  }
  .swiper-button {
    display: none;
  }
  .row .custom-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .searchBtn {
    width: 100%;
    margin-top: 10px;
  }
  .searchInner {
    background-color: transparent;
  }
  .searchInner .bgWhite {
    border-radius: 10px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 405px) {
  .horizonalSlider.slider-container .thumbs-swiper img {
    height: 70px;
  }
}

.validation-message {
  font-weight: 400;
  font-size: 16px;
  font-family: Work Sans;
  color: var(--error-color);
}
.success-message {
  font-weight: 400;
  font-size: 16px;
  font-family: Work Sans;
  color: var(--main-color);
}
.loading-overlay {
  background: rgba(68, 68, 68, 0.7);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdown-item:focus, .dropdown-item:hover {
  background-color: transparent !important;
}
.react-datepicker-wrapper {
  width: 100%;
}

.text-main { color: var(--main-color); }
.text-decoration-none {text-decoration: none; }

.modal-content button.closeBtn {
  border: 1px solid #d7d7d7;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  width: 170px;
  background-color: transparent;
  font-family: SF Pro;
  color: #060506;
  font-size: 18px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: -0.44999998807907104px;
  text-align: left;
  text-align: center;
}
.closeBtn {
  border: 1px solid #d7d7d7;
  border-radius: 10px;
}
.closeBtn:hover {
  background-color: rgba(0, 0, 0, 0.699) !important;
  color: white !important;
  border: 1px solid #575757 !important;
}